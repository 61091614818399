<template>
  <!-- 内容 -->
  <div class="searchW">
    <div class="searchPage">
      <div v-show="isList">
        <div class="screenGoods">
          <div class="screenGoodsItem" @click="goodsScreen('sell_num')">
            <span :style="{ color: goodsScreenType.sell_num ? '#FF2442' : '' }"
              >销量</span
            >
            <img
              v-show="goodsScreenType.sell_num"
              style="width: 0.67708vw; height: 0.46875vw"
              src="../../assets/activeDown.png"
              alt=""
            />
            <img
              v-show="!goodsScreenType.sell_num"
              style="width: 0.67708vw; height: 0.46875vw"
              src="../../assets/down.png"
              alt=""
            />
          </div>
          <div class="screenGoodsItem" @click="goodsScreen('sell_price')">
            <span
              :style="{ color: goodsScreenType.sell_price ? '#FF2442' : '' }"
              >价格</span
            >
            <img
              v-show="!goodsScreenType.sell_price"
              style="width: 0.67708vw; height: 1.35417vw"
              src="../../assets/shangxia.png"
              alt=""
            />
             <img
             v-show="goodsScreenType.sell_price=='desc'"
              style="width: 0.67708vw; height: 1.35417vw"
              src="../../assets/screenUp.png"
              alt=""
            />
             <img
             v-show="goodsScreenType.sell_price=='asc'"
              style="width: 0.67708vw; height: 1.35417vw"
              src="../../assets/screenDown.png"
              alt=""
            />
          </div>
          <div class="screenGoodsItem" @click="goodsScreen('create_time')">
            <span
              :style="{ color: goodsScreenType.create_time ? '#FF2442' : '' }"
              >最新</span
            >
            <img
              v-show="goodsScreenType.create_time"
              style="width: 0.67708vw; height: 0.46875vw"
              src="../../assets/activeDown.png"
              alt=""
            />
            <img
              v-show="!goodsScreenType.create_time"
              style="width: 0.67708vw; height: 0.46875vw"
              src="../../assets/down.png"
              alt=""
            />
          </div>
        </div>
        <GoodsList :list="list"></GoodsList>
        <infinite-loading
          spinner="spiral"
          ref="infiniteLoading"
          @infinite="infiniteHandler"
          :distance="200"
          class="infinite-loading-wrap"
          style="color: #999999; font-size: 0.83333vw"
        >
          <span slot="no-more">到底了亲～</span>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
      <!-- 为你推荐板块 -->
      <div class="recommendedW" v-show="!isList">
        <div class="searchNoResults">
          <img src="../../assets/searchNoResults.png" alt="" />
          <div>当前搜索无结果可以试试输入其他～</div>
        </div>
        <div class="recommended">
          <div class="recommendedTitle">
            <div class="title_en">You may also like</div>
            <div class="title_zh-cn">猜你喜欢</div>
          </div>
          <GoodsList :list="list"></GoodsList>
          <infinite-loading
            spinner="spiral"
            ref="infiniteLoading"
            @infinite="infiniteHandler"
            :distance="200"
            class="infinite-loading-wrap"
            style="color: #999999; font-size: 0.83333vw"
          >
            <div slot="no-more">到底了亲～</div>
            <div slot="no-results">暂无数据~</div>
          </infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsList from "../../components/goodsList/goodsList.vue";
import { getGoodsList } from "../../utils/api.js";

export default {
  components: {
    GoodsList,
  },

  data() {
    return {
      list: [],
      keyword: "",
      page: {
        page_num: 1, //页数
        page_limit: 12, //条数
      },
      goodsScreenType: {
        sell_num: "desc",
      },
      isList: true,
      state: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.query.keyword != from.query.keyword) {
        this.keyword = to.query.keyword;
        this.page.page_num = 1;
        this.list = [];
        this.arrlist = [];
        this.getGoodsListss(true);
        this.state.reset();
      }
    },
  },
  created() {
    this.getGoodsListss();
  },
  mounted() {},
  beforeMount() {
    let pMountedTimer = window.setInterval(() => {
      if (window.MountedType == 0) {
        window.clearInterval(pMountedTimer);
        window.parentMounted = this._isMounted; // _isMounted是当前实例mouned()是否执行 此时为false
        console.log(window);
      }
    }, 500);
  },
  methods: {
    goodsScreen(type) {
      this.page.page_num = 1;
      this.list = [];
      if (type == "sell_num") {
        this.goodsScreenType = {
          sell_num: "desc",
        };
      } else if (type == "sell_price") {
        if (this.goodsScreenType.sell_price == "desc") {
          this.goodsScreenType = {
            sell_price: "asc",
          };
        } else {
          this.goodsScreenType = {
            sell_price: "desc",
          };
        }
      } else if (type == "create_time") {
        this.goodsScreenType = {
          create_time: "desc",
        };
      }
      this.getGoodsLists(this.state);
      this.state.reset();
    },
    infiniteHandler($state) {
      this.state = $state;
      let pMountedTimer = window.setInterval(() => {
        console.log(window);
        if (window.MountedType == 0 && window.parentMounted) {
          window.clearInterval(pMountedTimer);
          this.getGoodsLists($state);
        }
      }, 500);
    },

    getGoodsLists($state) {
      let data = null;
      console.log(this.isList);
      if (this.isList) {
        data = {
          page: this.page,
          sort: this.goodsScreenType,

          map: {
            name: this.keyword,
          },
        };
        if (!this.keyword) {
          delete data.map;
        }
      } else {
        data = {
          page: this.page,
        };
      }

      getGoodsList(data).then((res) => {
        this.page.page_num++;
        if (res.data.length) {
          // this.list = this.list.concat(res.data);
          /* 去重 */
          let arr = this.list.concat(res.data)
						let map = new Map();
						for (let item of arr) {
							map.set(item.id, item);
						}
						this.list = [...map.values()];
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    getGoodsListss(type) {
      this.keyword = this.$route.query.keyword;
      let data = {
        page: this.page,
        map: {
          name: this.$route.query.keyword,
        },
      };

      if (!this.$route.query.keyword) {
        delete data.map;
      }
      getGoodsList(data).then((res) => {
        if (res.page.data_count) {
          this.isList = true;
        } else {
          this.isList = false;
        }
        this.list = res.data;
        window.MountedType = 0;
        window.parentMounted = type ? type : false; // _isMounted是当前实例mouned()是否执行 此时为false
      });
    },

    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
        console.log(this.$refs.cardShow);
      } else {
        this.$refs.cardShow.prev();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.searchW {
  .searchPage {
    margin: auto;
    width: 1316px;
    padding: 30px 62px;

    .screenGoods {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .screenGoodsItem {
        cursor: pointer;
        margin-right: 20px;
        font-size: 24px;
        color: #666666;
        display: flex;
        align-items: center;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .recommendedW {
    .recommended {
      margin: auto;
      width: 1316px;
      padding: 100px 0;

      .recommendedTitle {
        margin-bottom: 50px;

        .title_en {
          text-align: center;
          margin-bottom: 6px;
          font-size: 26px;
          font-weight: 600;
          color: #666666;
          letter-spacing: 10px;
        }

        .title_zh-cn {
          text-align: center;
          font-size: 26px;
          font-weight: 900;
          color: #ff2442;
          letter-spacing: 13px;
        }
      }
    }

    .searchNoResults {
      padding-top: 100px;
      text-align: center;
      img {
        width: 156px;
        height: 156px;
        margin-bottom: 50px;
      }
      div {
        font-size: 24px;
        font-weight: 500;
        color: #666666;
      }
    }
  }
}

/deep/.recommendedGoodsList {
  .recommendedGoodsItem {
    &:nth-child(3n) {
      margin-right: 24px;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>