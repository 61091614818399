<template>
  <div class="home">
    <!-- 内容 -->
    <div class="contentBox">
      <!-- 轮播图 -->
      <el-carousel trigger="click" height="36.45833vw" direction="vertical">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index" >
          <img style="width: 100%; height: 100%" :src="item.img" alt="" />
        </el-carousel-item>
      </el-carousel>
      <!-- 为你推荐板块 -->
      <div class="recommendedW">
        <div class="recommended">
          <div class="screenGoods">
            <div class="screenGoodsItem" @click="goodsScreen('sell_num')">
              <span
                :style="{ color: goodsScreenType.sell_num ? '#FF2442' : '' }"
                >销量</span
              >
              <img
                v-show="goodsScreenType.sell_num"
                style="width: 0.67708vw; height: 0.46875vw"
                src="../../assets/activeDown.png"
                alt=""
              />
              <img
                v-show="!goodsScreenType.sell_num"
                style="width: 0.67708vw; height: 0.46875vw"
                src="../../assets/down.png"
                alt=""
              />
            </div>
            <div class="screenGoodsItem" @click="goodsScreen('sell_price')">
              <span
                :style="{ color: goodsScreenType.sell_price ? '#FF2442' : '' }"
                >价格</span
              >
              <img
                v-show="!goodsScreenType.sell_price"
                style="width: 0.67708vw; height: 1.35417vw"
                src="../../assets/shangxia.png"
                alt=""
              />
              <img
                v-show="goodsScreenType.sell_price == 'desc'"
                style="width: 0.67708vw; height: 1.35417vw"
                src="../../assets/screenUp.png"
                alt=""
              />
              <img
                v-show="goodsScreenType.sell_price == 'asc'"
                style="width: 0.67708vw; height: 1.35417vw"
                src="../../assets/screenDown.png"
                alt=""
              />
            </div>
            <div class="screenGoodsItem" @click="goodsScreen('create_time')">
              <span
                :style="{ color: goodsScreenType.create_time ? '#FF2442' : '' }"
                >最新</span
              >
              <img
                v-show="goodsScreenType.create_time"
                style="width: 0.67708vw; height: 0.46875vw"
                src="../../assets/activeDown.png"
                alt=""
              />
              <img
                v-show="!goodsScreenType.create_time"
                style="width: 0.67708vw; height: 0.46875vw"
                src="../../assets/down.png"
                alt=""
              />
            </div>
          </div>
          <GoodsList :list="list"></GoodsList>
          <infinite-loading
            spinner="spiral"
            ref="infiniteLoading"
            @infinite="infiniteHandler"
            :distance="200"
            class="infinite-loading-wrap"
            style="color: #999999; font-size: 0.83333vw"
          >
            <span slot="no-more">到底了亲～</span>
            <span slot="no-results">没有更多了</span>
          </infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsList from "../../components/goodsList/goodsList.vue";
import { getGoodsList ,getBannerList } from "../../utils/api.js";

export default {
  components: {
    GoodsList,
  },

  data() {
    return {
      bannerList: [],
      videoTime: "",
      timer: "",
      list: [],
      page: {
        page_num: 1, //页数
        page_limit: 12, //条数
      },
      goodsScreenType: {
        sell_num: "desc",
      },
      state: "",
    };
  },
  mounted() {
    this.getBannerLists();
  },
  methods: {
    jump(){
      window.open(`../../components/3.html?${encodeURIComponent('天街喵')}`, "_blank"); //跳转新页面
    },
      //  请求轮播图
    getBannerLists() {
      // console.log(this.$API.);
      getBannerList().then((res) => {
        this.bannerList = res.data;
      });
    },
    goodsScreen(type) {
      // this.goodsScreenType = type
      this.page.page_num = 1;
      // this.list = [];
      if (type == "sell_num") {
        this.goodsScreenType = {
          sell_num: "desc",
        };
      } else if (type == "sell_price") {
        if (this.goodsScreenType.sell_price == "desc") {
          this.goodsScreenType = {
            sell_price: "asc",
          };
        } else {
          this.goodsScreenType = {
            sell_price: "desc",
          };
        }
      } else if (type == "create_time") {
        this.goodsScreenType = {
          create_time: "desc",
        };
      }
      // this.infiniteHandler(this.state)
      this.getGoodsData(this.state);
      this.state.reset();
    },

    infiniteHandler($state) {
      this.state = $state;
      this.getGoodsData($state);
    },

    getGoodsData($state) {
      let data = {
        page: this.page,
        sort: this.goodsScreenType,
      };
      getGoodsList(data).then((res) => {
        let page_num = this.page.page_num;
        this.page.page_num++;
        if (page_num == 1) {
          this.list = res.data;
          $state.loaded();
        } else {
          if (this.list.length >= res.page.data_count) {
            $state.complete();
          } else {
            let arr = this.list.concat(res.data);
            let map = new Map();
            for (let item of arr) {
              map.set(item.id, item);
            }
            this.list = [...map.values()];
            $state.loaded();
          }
        }
        // let page = res.page;
        // let page_num = Math.ceil(page.data_count / page.page_limit);
        // if (this.page.page_num <= page_num) {
        //   this.page.page_num++;
        //   if (res.data.length < this.page.page_limit) {
        //     this.list = this.list.concat(res.data);
        //     $state.complete();
        //   } else {
        //     if (res.data.length) {
        //       this.list = this.list.concat(res.data);
        //       this.isList = true;
        //       $state.loaded();
        //     } else {
        //       $state.complete();
        //     }
        //   }
        // } else {
        //   $state.complete();
        // }
      });
    },
    
  },
  
};
</script>

<style lang="less" scoped>
.liveChannelW {
  background: rgba(238, 238, 238, 0.3);

  .liveChannel {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 1140px;
    padding: 150px 140px 110px;

    .liveChannelLeft {
      width: 338px;
      padding-top: 60px;

      .liveChannelLeftTitle {
        margin-bottom: 50px;

        .title_en {
          margin-bottom: 10px;
          font-size: 26px;
          font-weight: 600;
          color: #666666;
          letter-spacing: 10px;
        }

        .title_zh-cn {
          font-size: 26px;
          font-weight: 900;
          color: #ff2442;
          letter-spacing: 13px;
        }
      }

      .liveChannelLeftIntroduction {
        margin-bottom: 98px;
        text-align: justify;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
      }

      .liveChannelLeftFindMore {
        cursor: pointer;
        width: 170px;
        height: 50px;

        .findMoreIcon {
          width: 170px;
          height: 50px;
        }
      }
    }

    .liveChannelRight {
      position: relative;
      width: 724px;
      height: 505px;
      background: #e6e6e6;
      border-radius: 11px;

      .liveChannelRightImg {
        position: absolute;
        top: -31px;
        right: 35px;
        width: 724px;
        height: 500px;
        border-radius: 11px;

        img {
          width: 724px;
          height: 500px;
          border-radius: 11px;
        }
      }
    }
  }
}

.showCircleW {
  .showCircle {
    padding: 100px 150px 120px 120px;
    width: 1150px;
    margin: auto;
    background-image: url("../../assets/showCircleBg.png");
    background-repeat: no-repeat;
    background-size: contain;

    .showCircleTop {
      display: flex;
      margin-bottom: 90px;

      .showCircleVideo {
        margin-right: 75px;
        width: 705px;
        height: 442px;

        .showCircleVideoImg {
          width: 705px;
          height: 442px;
        }
      }

      .showCircleInfo {
        padding-top: 26px;

        .showCircleTitle {
          margin-bottom: 30px;

          .title_en {
            margin-bottom: 10px;
            font-size: 26px;
            font-weight: 600;
            color: #666666;
            letter-spacing: 10px;
          }

          .title_zh-cn {
            font-size: 26px;
            font-weight: 900;
            color: #ff2442;
            letter-spacing: 13px;
          }
        }

        .showCircleInfoIntroduction {
          margin-bottom: 147px;
          text-align: justify;
          font-size: 14px;
          font-weight: 500;
          color: #999999;
        }

        .showCircleInfoBottom {
          display: flex;
          align-items: center;

          .getInto {
            cursor: pointer;
            margin-right: 30px;
            display: flex;
            align-items: center;

            .getIntoIcon {
              width: 18px;
              height: 18px;
              margin-right: 11px;
            }

            span {
              font-size: 18px;
              color: #666666;
            }
          }

          .showCircleInfoBottomNav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 144px;
            height: 20px;
            font-size: 13px;
            font-weight: 500;
            color: #666666;

            .line {
              width: 1px;
              height: 13px;
              background: #666666;
            }

            .showCircleInfoBottomNavItem {
              cursor: pointer;
            }
          }
        }
      }
    }

    .showCircleListBox {
      display: flex;
      align-items: center;

      .showCircleList {
        flex: 1;
        padding-left: 258px;

        .el-carousel {
          /deep/ .el-carousel__container {
            height: 367px;

            .el-carousel__item {
              flex: 1;
              display: flex;
            }
          }
        }

        .showCircleItem {
          margin-right: 50px;
          width: 241px;
          height: 367px;
          background: rgba(231, 202, 198, 0.3);
          border-radius: 8px;

          .videoAndImg {
            width: 100%;
            height: 186px;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            img,
            .video_box {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            img {
              // 解决图片底部默认空白缝隙问题
              display: block;
            }
          }

          .showCircleItemImg {
            width: 241px;
            height: 186px;
            border-radius: 8px 8px 0 0;
          }

          .showCircleItemInfo {
            padding: 10px 15px 18px;
            height: 181px;

            .showCircleItemInfoTitle {
              font-size: 14px;
              color: #666666;
              margin-bottom: 10px;
            }

            .touxiangAndName {
              display: flex;
              align-items: center;
              margin-bottom: 18px;

              .touxiangImg {
                width: 15px;
                height: 15px;
                margin-right: 6px;
              }

              .name {
                font-size: 13px;
                font-weight: 400;
                color: #999999;
              }
            }

            .transverse {
              margin-bottom: 21px;
              height: 1px;
              opacity: 0.8;
              border: 1px solid #e6e6e6;
            }

            .showCircleItemSet {
              display: flex;
              justify-content: space-between;
              padding: 0 25px;

              .showCircleItemSetItem {
                display: flex;
                flex-direction: column;
                align-items: center;

                .showCircleItemSetIcon {
                  margin-bottom: 9px;
                  width: 26px;
                  height: 22px;
                }

                span {
                  font-size: 13px;
                  color: #666666;
                }
              }
            }
          }
        }
      }

      .switchPage {
        .switchPageBtn {
          display: flex;
          width: 41px;
          height: 41px;
          cursor: pointer;

          .switchPageBtnImg {
            width: 41px;
            height: 41px;
          }
        }
      }
    }
  }
}

/deep/.recommendedGoodsList {
  .recommendedGoodsItem {
    &:nth-child(3n) {
      margin-right: 24px;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.recommendedW {
  background: rgba(238, 238, 238, 0.3);

  .recommended {
    margin: auto;
    width: 1316px;
    padding: 62px 62px 40px;

    .screenGoods {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .screenGoodsItem {
        cursor: pointer;
        margin-right: 20px;
        font-size: 24px;
        color: #666666;
        display: flex;
        align-items: center;

        span {
          margin-right: 5px;
        }
      }
    }

    .recommendedTitle {
      margin-bottom: 50px;

      .title_en {
        text-align: center;
        margin-bottom: 6px;
        font-size: 26px;
        font-weight: 600;
        color: #666666;
        letter-spacing: 10px;
      }

      .title_zh-cn {
        text-align: center;
        font-size: 26px;
        font-weight: 900;
        color: #ff2442;
        letter-spacing: 13px;
      }
    }
  }
}

// 播放器样式
video::-webkit-media-controls-timeline {
  // 进度条
  width: 100%;
  padding: 0;
}

//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none !important;
}

//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

//音量按钮
video::-webkit-media-controls-mute-button {
  display: none !important;
}

video::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}

//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none !important;
}

// 全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

//播放按钮
video::-webkit-media-controls-play-button {
  display: none !important;
}

// 播放控件
video::-webkit-media-controls {
  // 进度条
  opacity: 1;
  // 让鼠标指针一直显示
  cursor: pointer !important;
}
</style>