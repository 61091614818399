<template>
  <div
    class="swiper-video-container"
    :style="`width: ${innerWidth}; height: ${isHeight ? innerHeight : '38.80208vw'}`"
    @mousewheel="rollScroll"
  >
    <!-- 轮播区域 -->
    <el-carousel
      class="my-swipe"
      ref="playerSwiper"
      :loop="false"
      :autoplay="false"
      v-if="videoList.length"
      @change="_slideChange"
      :initial-index="Number(activeIndex)"
      indicator-position="none"
    >
      <el-carousel-item v-for="(item, index) in videoList" :key="item.id" >
        <div
          class="videoAndDrawer"
          :style="`width: ${innerWidth}; height: ${
            isHeight ? innerHeight : '38.80208vw'
          }`"
        >
          <!-- 播放器 -->
          <video
            class="video-content video"
            :ref="`video${index}`"
            :id="`player${index}`"
            :src="item.video_address"
            preload="auto"
            autoplay
            controls
            loop
            width="100%"
            height="100%"
            type="video/mp4"
            playsinline="true"
            x5-playsinline="true"
            webkit-playsinline="true"
            x-webkit-airplay="allow"
            oncontextmenu="return false;"
            controlslist="nodownload noremoteplayback noplaybackrate"
            :disablePictureInPicture="true"
          ></video>
        </div>

        <!-- <div class="controls">
          <div ref="progress" class="progress" @click="jumpProgress">
            <div ref="loaded" id="loaded" class="loaded"></div>
            <div class="bar"></div>
          </div>
          <div class="controlSet">
            <span ref="isPlay" @click="playAndPause" class="switch">
              <i class="el-icon-video-pause" v-show="videoList[activeIndex].play"></i>
              <i class="el-icon-video-play" v-show="!videoList[activeIndex].play"></i>
            </span>
            <div class="timer">
              <span ref="currPlayTime" class="current">{{ currPlayTime }}</span>
              <span style="margin: 0 0.26042vw">/</span>
              <span ref="totalTime" class="total">{{ totalTime }}</span>
            </div>
            <a ref="expand" class="expand" @click="fullScreen"></a>
          </div>
        </div> -->
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getVideoCommentsList } from "../../utils/api.js";

export default {
  props: ["videoList", "innerWidth", "innerHeight", "isHeight", "initialSlide"],
  data() {
    return {
      swiperOptions: {
        direction: "vertical",
        // mousewheel: true,
        initialSlide: this.$props.initialSlide,
        // mousewheelControlForceToAxis : true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      ismouseover: false,
      drawer: false,
      totalTime: null,
      currPlayTime: "00:00",
      activeIndex: this.$props.initialSlide ? this.$props.initialSlide : 0,
      timeOut: null,
      page: {
        page_num: 1, //页数
        page_limit: 10, //条数
      },
      commentsList: [],
    };
  },

  computed: {
    swiper() {
      return this.$refs.playerSwiper.$swiper;
    },
  },

  mounted() {
    //mounted监听处理
    // this.videoPlay();
    // this.getCommentsList();
  },
  methods: {
    //展开子评论
    expandsComment(item, index) {
      console.log(index);
      let num = 1;
      if (index + 1 <= 10) {
        num = 1;
      } else {
        num = ((index + 11) + "").substring(0, 1);
      }
      console.log(num);
      let page_num = item.child_comment_list.page.page_num;
      let video_uuid = this.videoList[this.activeIndex].uuid;
      getVideoCommentsList({
        map: {
          video_uuid,
          type_value: "VIDEO",
        },
        page: {
          page_num: num,
          page_limit: 10,
        },
        query_condition: {
          is_need_child_comment: "1",
          child_comment_page: {
            page_num: page_num + 1,
            page_limit: "2",
          },
        },
      }).then((res) => {
        // this.res.data
        console.log(res);
        res.data.forEach((itemA) => {
          console.log(itemA.id);
          console.log(item.id,'aaa');
          // console.log(video_uuid);
          if (itemA.id == item.id) {
            console.log(itemA);
            item.child_comment_list.data.push(...itemA.child_comment_list.data);
            item.child_comment_list.page.page_num =
              itemA.child_comment_list.page.page_num;
          }
        });
      });
    },
    //收回子评论
    collapseComment(item, index) {
      let video_uuid = this.videoList[this.activeIndex].uuid;
      let num = 1;
      if (index + 1 <= 10) {
        num = 1;
      } else {
        num = index + 1 + "".substring(0, 1);
      }

      getVideoCommentsList({
        map: {
          video_uuid,
          type_value: "VIDEO",
        },
        page: {
          page_num: num,
          page_limit: 10,
        },
        query_condition: {
          is_need_child_comment: "1",
          child_comment_page: {
            page_num: 1,
            page_limit: "2",
          },
        },
      }).then((res) => {
        // this.res.data
        console.log(res);
        res.data.forEach((itemA) => {
          // console.log(itemA);
          // console.log(video_uuid);
          if (itemA.id == item.id) {
            console.log(itemA);
            item.child_comment_list.data = itemA.child_comment_list.data;
            item.child_comment_list.page.page_num =
              itemA.child_comment_list.page.page_num;
          }
        });
      });
    },

    infiniteHandler($state) {
      console.log($state);
      //   this.state = $state;
      this.page.page_num++;
      this.getCommentsList($state);
    },
    getCommentsList($state) {
      console.log(this.videoList);
      console.log(this.videoList[this.activeIndex].uuid);

      getVideoCommentsList({
        map: {
          video_uuid: this.videoList[this.activeIndex].uuid,
          type_value: "VIDEO",
        },
        page: this.page,
        query_condition: {
          is_need_child_comment: "1",
          child_comment_page: {
            page_num: 1,
            page_limit: "2",
          },
        },
      }).then((res) => {
        // this.commentsList = res.data;
        if (res.data.length) {
          this.commentsList = this.commentsList.concat(res.data);
          if ($state) {
            $state.loaded();
          }
        } else {
          if ($state) {
            $state.complete();
          }
        }
      });
    },
    arrowClick(val) {
      if (val === "right") {
        this.$refs.playerSwiper.next();
      } else {
        this.$refs.playerSwiper.prev();
      }
    },
    rollScroll(e) {
      // chrome、ie使用的wheelDelta，火狐使用detail
      if (!this.ismouseover) {
        e.preventDefault();
      }
      let scrollVal = e.wheelDelta || e.detail;
      // 节流
      if (!this.ismouseover) {
        if (!this.timeOut) {
          this.timeOut = setTimeout(() => {
            this.timeOut = null;
            scrollVal > 0
              ? this.$refs.playerSwiper.prev()
              : this.$refs.playerSwiper.next();
          }, 300);
        }
      }
    },
    clickDrawer() {
      this.drawer = true;
      this.getCommentsList();
    },
    closeDrawer() {
      this.drawer = false;
    },
    containerWidth() {
      let innerWidth = this.$props.innerWidth;
      let num = "742px";
      if (this.drawer) {
        return { width: `calc(${innerWidth} - ${num})` };
      }
    },
    // swiper 切换
    _slideChange(e) {
      const index = e;
      var player = document.getElementById(`player${index}`);
      this.activeIndex = e;
      this.page.page_num = 1;
      this.commentsList = [];
      this.$emit("activeIndex", this.activeIndex);
      this.getCommentsList();
      if (player.paused) {
        this.videoList.forEach((item, s_index) => {
          if (s_index == index) {
            document.getElementById(`player${s_index}`).play();
            item.play = true;
          } else {
            document.getElementById(`player${s_index}`).pause();
            item.play = false;
          }
        });
      } else {
        this.videoList.forEach((item, s_index) => {
          document.getElementById(`player${s_index}`).pause();
          item.play = false;
        });
      }
    },
    // 进入视频状态
    videoPlay() {
      let self = this;

      // 当视频可以播放的时候
      const index = this.activeIndex;
      console.log(this.$refs[`video${index}`]);
      let videoData = this.$refs[`video${index}`][0];
      videoData.oncanplay = function () {
        return (self.totalTime = self.getFormatTime(videoData.duration)); // 显示视频总时长
      };

      // 播放进度
      videoData.ontimeupdate = function () {
        let currTime = this.currentTime;
        let duration = this.duration;

        let pre = (currTime / duration) * 100 + "%"; // 百分比

        let loaded = document.getElementById("loaded");
        loaded.style.width = pre; // 显示进度条

        self.currPlayTime = self.getFormatTime(currTime); // 显示当前播放进度时间
      };

      // 播放完毕还原设置
      videoData.onended = function () {
        let loaded = document.getElementById("loaded");
        loaded.style.width = "0%"; //进度条为0
        this.currentTime = 0;
      };
    },

    // 获取视频总时长
    getFormatTime(timer) {
      console.log(timer, "sss");
      var time = timer || 0;
      // var h = parseInt(time / 3600);
      var m = parseInt((time % 3600) / 60);
      var s = parseInt(time % 60);
      // h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return m + ":" + s;
    },

    // 播放/暂定
    playAndPause() {
      const index = this.activeIndex;
      var myVideo = document.getElementById(`player${index}`);
      console.log(myVideo);
      if (myVideo.paused) {
        myVideo.play();
        console.log(321);
        this.videoPlay();
      } else {
        myVideo.pause();
      }
    },

    // 跳跃播放
    jumpProgress(e) {
      let myVideo = document.getElementById("myVideo");
      var event = e || window.event;
      myVideo.currentTime =
        (event.offsetX / this.$refs.progress.offsetWidth) * myVideo.duration;
    },
 
    // 全屏
    fullScreen() {
      this.$refs.video.webkitRequestFullScreen();
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-video-container {
  display: flex;
  position: relative;
  // position: fixed;
  // width: 88%;
  // height: calc(100% - 130px);
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;

  .my-swipe {
    background: #000;
    position: relative;
    width: 100%;
    // 解决滑动轮播时有白色线条的Bug
    height: 100%;

    /deep/.el-carousel__container {
      // position: relative;
      display: flex;
      height: 100%;

      .el-carousel__item {
        display: flex;
      }

      .video-content {
        width: 100%;
        height: 100%;
        background-color: #000;
        // object-fit: cover;
      }

      .right_menus {
        position: absolute;
        width: 50px;
        bottom: 80px;
        right: 20px;
        z-index: 1;
        text-align: center;

        .menuClick {
          position: relative;
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .avatar {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }

          .follow {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 50%;
            bottom: -6px;
            transform: translateX(-50%);
            font-size: 12px;
            color: #fff;
            background-color: #fe2c55;
            border-radius: 50%;
            line-height: 20px;
          }
        }

        .click-info {
          position: relative;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-bottom: 6px;
          }

          i {
            font-size: 24px;
            color: #fff;
          }

          .text {
            font-size: 13px;
            color: #ffffff;
          }
        }
      }

      .videocart {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 180px;
        padding: 0 30px;
        z-index: 9;
        .videocartIcon {
          width: 25px;
          height: 21px;
          margin-right: 5px;
        }
        .goodsName {
          font-size: 14px;
          color: #ffffff;
        }
      }

      .text-container {
        position: absolute;
        width: 30%;
        left: 0;
        bottom: 80px;
        padding: 0 30px;
        box-sizing: border-box;
        z-index: 1;

        .nickname {
          margin-bottom: 10px;
          width: 100%;
          font-size: 13px;
          color: #ffffff;
          text-align: left;
        }

        .msg {
          width: 100%;
          box-sizing: border-box;
          font-size: 14px;
          text-align: left;
          color: #fff;
        }
      }

      .videoAndDrawer {
        width: calc(100% - 472px);
        position: relative;
        display: flex;
      }
    }
  }

  // 切换按钮
  .toggle-swiper {
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 80px;
    background-color: #323442;
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 40px;
    position: static;
    color: #fff;
    margin-top: 0;

    &::after {
      content: "";
    }

    &:hover {
      color: #fe2c55;
    }

    i {
      font-weight: bold;
    }
  }
}

// /* 隐藏video 全屏按钮 */
.video::-webkit-media-controls-fullscreen-button {
  display: none;
}

// /* 隐藏video 播放按钮 */
// .video::-webkit-media-controls-play-button {
//   display: none;
// }

// /* 隐藏video 进度条 */
// .video::-webkit-media-controls-timeline {
//   display: none;
// }

// /* 隐藏video 观看的当前时间 */
// .video::-webkit-media-controls-current-time-display {
//   display: none;
// }

// /* 隐藏video 剩余时间 */
// .video::-webkit-media-controls-time-remaining-display {
//   display: none;
// }

// /* 隐藏video 音量按钮 */
// .video::-webkit-media-controls-mute-button {
//   display: none;
// }

// .video::-webkit-media-controls-toggle-closed-captions-button {
//   display: none;
// }

// /* 隐藏video 音量的控制条 */
// .video::-webkit-media-controls-volume-slider {
//   display: none;
// }

// /* 隐藏video 所有控件 */
// .video::-webkit-media-controls-enclosure {
//   display: none;
// }

.player {
  width: 720px;
  height: 360px;
  margin: 0 auto;
  /* 加载中动画 */
  background-size: 48px 48px;
  background-repeat: no-repeat;
  position: relative;
}

// video {
//     display: none;
//     height: 100%;
//     margin: 0 auto;
// }

.controls {
  width: 100%;
  height: 0.4rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 0.7);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
}

.progress {
  width: 100%;
  height: 3px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #555;
  cursor: pointer;
  // position: absolute;
  // top: 16px;
  // left: 0;
}

.line {
  width: 80%;
  height: 3px;
  background-color: RGBA(67, 73, 60, 1);
}

.loaded {
  width: 30%;
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.bar {
  width: 100%;
  height: 3px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.controlSet {
  padding-left: 30px;
  display: flex;
  align-items: center;
  height: 37px;
}

.switch {
  display: flex;
  font-size: 20px;
  color: #fff;
  // position: absolute;
  // top: 11px;
  // left: 11px;
  /* 播放按钮 */
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
.playIcon {
  width: 7px;
  height: 11px;
}

.timer {
  margin-left: 6px;
  color: #fff;
  font-size: 14px;
  span {
    font-size: 11px;
    color: #ffffff;
  }
  .total {
    color: #4b4b4b;
  }
}

.switch:hover {
  color: blue;
}

.expand {
  display: block;
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  right: 11px;
  top: 11px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}

.expand:hover {
  color: blue;
}

.drawer {
  padding-bottom: 88px;
  z-index: 9;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  min-width: 472px;
  width: 472px;
  background: #e0e0de;
  // background-image: url(../../assets/commentsListBg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100% 100%;
  border-radius: 4px;

  .drawerTop {
    padding: 23px 34px 18px 44px;
    display: flex;
    justify-content: flex-end;

    img {
      width: 21px;
      height: 21px;
    }
  }

  .allcommentNum {
    padding-left: 44px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
  }

  .commentsList {
    padding: 0 34px 0 44px;
    overflow-y: scroll;
    height: calc(100% - 188px);
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none;
    }

    .personCommentItem {
      display: flex;
      margin-bottom: 22px;

      .personCommentLeft {
        display: flex;
        width: 38px;
        margin-right: 10px;

        img {
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }
      }

      .personCommentRight {
        .personCommentRightTop {
          margin-bottom: 5px;
          display: flex;
          align-items: center;

          .name {
            font-size: 14px;

            color: #333333;
            margin-right: 10px;
          }

          .timeAndIp {
            font-size: 13px;

            color: #333333;
          }
        }

        .personCommentRightContent {
          font-size: 16px;

          color: #333333;
          margin-bottom: 12px;
        }

        .personCommentBottom {
          display: flex;

          .dz,
          .zd {
            display: flex;
            align-items: center;
            margin-right: 10px;

            img {
              width: 12px;
              height: 15px;
              margin-right: 5px;
            }

            .num {
              font-size: 13px;
            }
          }

          .huifu {
            cursor: pointer;
            margin-left: 15px;
            font-size: 13px;
            color: #333333;
          }
        }
      }
    }
    .expandAndCollapse {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .expands {
        margin-right: 25px;
        font-size: 13px;
      }
      .collapse {
        font-size: 13px;
        color: #666666;
      }
    }
  }

  .drawerBottomDownload {
    position: absolute;
    bottom: 0;
    width: 472px;
    height: 58px;
    background: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    span {
      color: #ff2442;
    }
  }
}
</style>